import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default ({ children }) => (
  <div style={{ margin: `2rem auto`, maxWidth: 1000, padding: `0 1rem` }}>
    <header style={{ marginBottom: `1.5rem` }}>
    <Helmet>
      <title>Cale Village | TCVOA</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
      <Link to="/" style={{ textShadow: `none`, backgroundImage: `none` }}>
        <h3 style={{ display: `inline` }}>Cale Village</h3>
      </Link>
      <ul style={{ listStyle: `none`, float: `right` }}>
        <ListLink to="/news/">News</ListLink>
        <ListLink to="/info/">Info</ListLink>
        <ListLink to="/factor/">Factor</ListLink>
        <ListLink to="/bins/">Bins</ListLink>
        <ListLink to="/parking/">Parking</ListLink>
        <ListLink to="/contacts/">Contacts</ListLink>
      </ul>
    </header>
    {children}
  </div>
)